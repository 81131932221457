import hash from 'object-hash';
import { useEffect, useState } from 'react';
import { Button, Modal, Spinner, Toast } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Tab from 'react-bootstrap/Tab';
import Table from 'react-bootstrap/Table';
import Tabs from 'react-bootstrap/Tabs';

import Container from 'react-bootstrap/Container';
import { FaPlus, FaPencilAlt } from 'react-icons/fa';
import { useOutletContext } from 'react-router-dom';
import { Backend, ITenantMapping, IUser } from '../App';

const INITIAL_TENANT_STATE: ITenantMapping = {
    mapping: new Map(),
};

const ManageTenants = () => {

    // const navigate = useNavigate();
    let ctx = useOutletContext<any>();
    let user = ctx.user;

    const [tenants, setTenants] = useState(INITIAL_TENANT_STATE);
    const [users, setUsers] = useState([]);
    const [tenantsHash, setTenantsHash] = useState("");
    const [name, setName] = useState<string>("");
    const [numSlots, setNumSlots] = useState<number>(1);
    const [numViews, setNumViews] = useState<number>(1);
    const [numUsedViews, setUsedNumViews] = useState<number>(0);
    const [selectedEditTenantKey, setSelectedEditTenantKey] = useState<string>("");

    const [errorMessage, setErrorMessage] = useState("");
    const [showCreateModal, setCreateModal] = useState(false);
    const [showEditModal, setEditModal] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);

    let loading = tenantsHash === "";


    useEffect(() => {
        if (!user) {
            return;
        }

        setShowErrorAlert(false);

        user.getIdToken().then((token: string) =>
            fetch(`${Backend}/tenants`,
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    if (!response.ok) {
                        return response.json().then(e => new Error(e["message"]))
                    }
                    return response.json();
                })
                .then(data => {
                    // pivot by user -> tenant
                    let users: any = [];
                    Object.keys(data).forEach((tenantName: string) => {
                        if (tenantName === "unassignedUsers") {
                            data[tenantName].forEach((u: IUser) => {
                                users.push({
                                    uid: u.uid,
                                    displayName: u.displayName,
                                    email: u.email,
                                    tenantName: ""
                                })
                            });
                        } else {
                            data[tenantName].users.forEach((u: IUser) => {
                                users.push({
                                    uid: u.uid,
                                    displayName: u.displayName,
                                    email: u.email,
                                    tenantName: tenantName
                                })
                            });
                        }
                    });

                    // remove the unassigned key, as it's not a real tenant
                    delete data["unassignedUsers"]

                    users.sort((a: IUser, b: IUser) => a.email.localeCompare(b.email))
                    setUsers(users);
                    setTenants({ mapping: new Map(Object.entries(data)) });
                    setTenantsHash(hash(data));
                }).catch(e => {
                    console.log(e);
                    setErrorMessage(`${e.message} - could not retrieve tenants, please try again later.`);
                    setShowErrorAlert(true);
                }));
        // eslint-disable-next-line
    }, [user, tenantsHash]);


    const handleCreateSubmit = async (event: any) => {
        event.preventDefault();

        setCreateModal(false);
        let token = await user.getIdToken()
        var raw = JSON.stringify({
            "tenantName": name,
            "slotAllowance": numSlots,
            "viewBudget": numViews
        });

        let fetchResult = await fetch(`${Backend}/tenants`, {
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }, body: raw
        });

        let body = await fetchResult.json();
        if (!fetchResult.ok) {
            setErrorMessage("Could not create tenant, error was: " + body.message);
            setShowErrorAlert(true);
        } else {
            // that should refresh from the backend
            setTenantsHash("")
        }
    }

    const handleEditSubmit = async (event: any) => {
        event.preventDefault();

        setEditModal(false);
        let token = await user.getIdToken()
        var raw = JSON.stringify({
            "tenantName": selectedEditTenantKey,
            "slotAllowance": numSlots,
            "viewBudget": numViews,
            "viewBudgetUsed": numUsedViews
        });

        let fetchResult = await fetch(`${Backend}/tenants`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }, body: raw
        });

        let body = await fetchResult.json();
        if (!fetchResult.ok) {
            setErrorMessage("Could not edit partner, error was: " + body.message);
            setShowErrorAlert(true);
        } else {
            // that should refresh from the backend
            setTenantsHash("")
        }
    }


    const handleTenantAssignment = async (event: any) => {
        event.preventDefault();

        let target = event.target;
        let tenantName = target.value;
        let userId = event.target.id.replace("tenant_input_", "");
        var raw = JSON.stringify({
            "tenantName": tenantName,
            "userId": userId
        });
        let token = await user.getIdToken()
        let fetchResult = await fetch(`${Backend}/tenants`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }, body: raw
        });

        let body = await fetchResult.json();
        if (!fetchResult.ok) {
            setErrorMessage("Could not assign user to Partner, error was: " + body.message);
            setShowErrorAlert(true);
        } else {
            // that should refresh from the backend
            setTenantsHash("")
        }
    }

    return (

        <Container>
            <Modal show={showCreateModal} onHide={() => setCreateModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Create Partner</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3">
                        <Form.Label>Partner Name</Form.Label>
                        <Form.Control type="text" maxLength={30} minLength={5}
                            value={name}
                            onChange={event => {
                                setName(event.target.value)
                            }} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Number of allocated campaign slots</Form.Label>
                        <Form.Control type="number"
                            min={1}
                            value={numSlots}
                            onChange={event => {
                                setNumSlots(Number(event.target.value))
                            }} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Number of allocated views</Form.Label>
                        <Form.Control type="number"
                            min={1}
                            value={numViews}
                            onChange={event => {
                                setNumViews(Number(event.target.value))
                            }} />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setCreateModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="primary" type="submit" onClick={handleCreateSubmit}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showEditModal} onHide={() => setEditModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Partner</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3">
                        <Form.Label>Partner Name</Form.Label>
                        <Form.Control type="text" maxLength={30} minLength={5} disabled={true} value={name} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Number of allocated campaign slots</Form.Label>
                        <Form.Control type="number"
                            min={1}
                            value={numSlots}
                            onChange={event => {
                                setNumSlots(Number(event.target.value))
                            }} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Number of allocated views</Form.Label>
                        <Form.Control type="number"
                            min={1}
                            value={numViews}
                            onChange={event => {
                                setNumViews(Number(event.target.value))
                            }} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>DANGER: Number of used views</Form.Label>
                        <Form.Control type="number"
                            min={0}
                            value={numUsedViews}
                            onChange={event => {
                                setUsedNumViews(Number(event.target.value))
                            }} />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setEditModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="primary" type="submit" onClick={handleEditSubmit}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>

            {loading &&
                <Container>
                    {showErrorAlert &&
                        <Toast
                            bg="danger"
                            onClose={() => setShowErrorAlert(false)}
                            show={showErrorAlert}>
                            <Toast.Body className="text-white">{errorMessage}</Toast.Body>
                        </Toast>
                    }
                    {!showErrorAlert &&
                        <Container>
                            <p>Loading Agencies...</p>
                            <Spinner animation="border" role="status" />
                        </Container>
                    }
                </Container>
            }
            {!loading &&
                <Container className='p-5 mb-4 rounded-3'>
                    <Tabs
                        defaultActiveKey="tenants"
                        id="tenant-tab"
                        className="mb-3"
                        variant='tabs'
                    >
                        <Tab eventKey="tenants" title="Agencies">
                            <Table striped bordered hover variant='dark'>
                                <thead>
                                    <tr>
                                        <th>Partner</th>
                                        <th># Users</th>
                                        <th># Slots</th>
                                        <th>View Budget</th>
                                        <th>Edit</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        tenants.mapping &&
                                        Array.from(tenants.mapping).map(([k, v]) => {
                                            return <tr key={"tenant_row_" + k}>
                                                <td>{k}</td>
                                                <td>{v.users.length}</td>
                                                <td>
                                                    {v.slotAllowance}
                                                </td>
                                                <td>
                                                    {v.viewBudgetUsed} / {v.viewBudget}
                                                </td>
                                                <td>
                                                    <Button
                                                        className="sc_button"
                                                        onClick={() => {
                                                            setSelectedEditTenantKey(k);
                                                            setName(k);
                                                            setNumSlots(v.slotAllowance)
                                                            setNumViews(v.viewBudget)
                                                            setUsedNumViews(v.viewBudgetUsed)
                                                            setEditModal(true)
                                                        }}>
                                                        <FaPencilAlt></FaPencilAlt>
                                                    </Button>

                                                </td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </Table>

                            <Button
                                type="submit"
                                className="sc_button"
                                onClick={() => setCreateModal(true)}>
                                <FaPlus style={{ marginRight: '0.5em' }} />
                                Add Partner
                            </Button>
                        </Tab>
                        <Tab eventKey="users" title="Users">
                            <Table striped bordered hover variant='dark'>
                                <thead>
                                    <tr>
                                        <th>UserID</th>
                                        <th>Display Name</th>
                                        <th>Email</th>
                                        <th>Partner</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        users.map(function (object: any, i: number) {
                                            return <tr key={"user_row_" + i}>
                                                <td>{object.uid}</td>
                                                <td>{object.displayName}</td>
                                                <td>{object.email}</td>
                                                <td>
                                                    <Form.Select
                                                        id={"tenant_input_" + object.uid}
                                                        key={"tenant_input_" + object.uid}
                                                        value={object.tenantName}
                                                        onChange={handleTenantAssignment}
                                                    >
                                                        {tenants.mapping &&
                                                            Array.from(tenants.mapping).map(([k, _]) => {
                                                                return <option key={k} id={k}>{k}</option>
                                                            }).concat(
                                                                <option key={""} id={""}>{""}</option>
                                                            )
                                                        }
                                                    </Form.Select>
                                                </td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </Table>
                        </Tab>
                    </Tabs>
                </Container>
            }</Container>
    );
}

export default ManageTenants;
