// import '../App.css'
import { useState } from 'react';
import { Alert, Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import { FaArrowAltCircleLeft } from 'react-icons/fa';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { Backend, LargeScreenDimension, SmallScreenDimension, ICampaign } from '../App';


const NewCampaign = () => {
  const ctx = useOutletContext<any>();
  let user = ctx.user;

  const navigate = useNavigate();
  const [name, setName] = useState<string>("");
  const [dimension, setDimension] = useState<string>(SmallScreenDimension);
  const [numViews, setNumViews] = useState<number>(10000);
  const [email, setEmail] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorAlert, setShowErrorAlert] = useState(false);

  const handleNameChange = async (event: any) => {
    setName(event.target.value);
  }

  const handleEmailChange = async (event: any) => {
    setEmail(event.target.value);
  }

  const handleNumViewsChange = async (event: any) => {
    setNumViews(Number(event.target.value));
  }

  const handleDimensionChange = async (event: any) => {
    if (event.target.value.includes("small")) {
      setDimension(SmallScreenDimension)
    } else {
      setDimension(LargeScreenDimension)
    }
  }


  const handleSubmit = async (event: any) => {
    event.preventDefault();

    let token = await user.getIdToken()
    var raw = JSON.stringify({
      "dimension": dimension,
      "name": name,
      "numViews": numViews,
      "email": email
    });

    let fetchResult = await fetch(`${Backend}/admin`, {
      method: 'PUT',
      headers: {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json'
      }, body: raw
    });

    let body = await fetchResult.json();
    if (!fetchResult.ok) {
      setErrorMessage("Could not create campaign, error was: " + body.message);
      setShowErrorAlert(true);
    } else {
      let defaultImage = "https://firebasestorage.googleapis.com/v0/b/metabeacon.appspot.com/o/Kiosk-advertise_here_large.png?alt=media&token=e365899c-5e05-46dc-a579-1ced91204606";
      if (dimension === SmallScreenDimension) {
        defaultImage = "https://firebasestorage.googleapis.com/v0/b/metabeacon.appspot.com/o/Kiosk-advertise_here-small.png?alt=media&token=0635a305-2af4-472c-8752-a363e95590b2";
      }
      const c: ICampaign = {
        id: body.id,
        creator: "",
        dimension: dimension,
        name: name,
        numViews: Number(numViews),
        purchasedViews: Number(numViews),
        consumedViews: Number(0),
        enabled: false,
        email: email,
        fileName: "",
        tenant: "",
        status: "",
        // TODO this is a bit of cheating
        imageUrl: defaultImage
      }
      await ctx.addCampaign(c);
      navigate(`/${body.id}`);
    }
  }

  return (
    <Container className='p-5 mb-4 rounded-3'>
      <h1 className="header">
        <Button type="submit" className="sc_button" onClick={() => navigate("/")}>
          <FaArrowAltCircleLeft />
        </Button>        /         Get started with a new Campaign
      </h1>

      <hr />

      {showErrorAlert &&
        <Alert variant="danger" onClose={() => { setShowErrorAlert(false) }} dismissible>
          <Alert.Heading>Something went wrong</Alert.Heading>
          <p>
            {errorMessage}
          </p>
        </Alert>
      }

      <Form className='tnc-form container justify-content-center align-items-center' onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <Form.Label>
            Name your campaign
          </Form.Label>
          <Form.Control required placeholder="my cool campaign"
            maxLength={20}
            tabIndex={1}
            value={name}
            onChange={handleNameChange} />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>
            Email
          </Form.Label>
          <Form.Control required placeholder="chris@nftplazas.com"
            type="email"
            tabIndex={2}
            value={email}
            onChange={handleEmailChange} />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>
            How many views do you want to show?
          </Form.Label>
          <Form.Control required placeholder="10000"
            type="number"
            tabIndex={3}
            value={numViews}
            onChange={handleNumViewsChange} />
        </Form.Group>
        <Form.Label>
          Choose which screen type you want to use:
        </Form.Label>
        <Form.Select onChange={handleDimensionChange} tabIndex={4}>
          <option>Small Screen ({SmallScreenDimension})</option>
          <option>Large Screen ({LargeScreenDimension})</option>
        </Form.Select>
        <br />
        <Button type="submit" className="sc_button" tabIndex={5}>Create</Button>
      </Form>
    </Container>
  );
}

export default NewCampaign;

