import 'bootstrap/dist/css/bootstrap.css';
import hash from 'object-hash';
import React, { Suspense } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import 'react-dropzone-uploader/dist/styles.css';
import { Trans, withTranslation } from 'react-i18next';
import { Link, Outlet } from "react-router-dom";
import { WrappedComponentProps } from 'react-with-firebase-auth';
import './App.css';
import logo from './assets/top-logo-MR-nftplazas.png';

// export const Backend = "http://localhost:5001/metabeacon/europe-west1"
export const Backend = "https://metabeacon.win/api"

export const LargeScreenDimension = "512x512"
export const SmallScreenDimension = "512x340"

export interface IUser {
  uid: string;
  email: string;
  displayName: string;
}

export interface ITenant {
  slotAllowance: number;
  users: IUser[];
  viewBudget: number;
  viewBudgetUsed: number;
}

export interface ITenantMapping {
  mapping: Map<string, ITenant>;
}

export interface ICampaign {
  id: string;
  name: string;
  email: string;
  numViews: number;
  purchasedViews: number;
  consumedViews: number;
  enabled: boolean | null;
  creator: string;
  tenant: string;
  fileName: string;
  dimension: string;
  imageUrl: string;
  status: string;
}

interface IManagementState {
  campaignHash: string;
  campaigns: ICampaign[];
  selectedCampaignId: string;
}

const INITIAL_STATE: IManagementState = {
  campaignHash: '',
  campaigns: [],
  selectedCampaignId: '',
};

class App extends React.Component<WrappedComponentProps, any> {

  public state: IManagementState;

  constructor(props: WrappedComponentProps) {
    super(props);

    this.onLoad = this.onLoad.bind(this);
    this.deleteStateCallback = this.deleteStateCallback.bind(this);
    this.selectSlotAt = this.selectSlotAt.bind(this);
    this.updateCampaignWithId = this.updateCampaignWithId.bind(this);
    this.addCampaign = this.addCampaign.bind(this);

    this.state = {
      ...INITIAL_STATE
    };
  }

  public async onLoad(apiResponse: any) {
    // we're using a hash here to stop propagating updates in case the response didn't change
    // for that we have to only hash things that we change in the UI
    let h = hash(apiResponse.campaigns.map((e: ICampaign) => {
      let c: ICampaign = {
        id: e.id,
        name: e.name,
        dimension: e.dimension,
        email: e.email,
        enabled: e.enabled,
        fileName: e.fileName,
        creator: e.creator,
        tenant: e.tenant,
        numViews: e.numViews,
        purchasedViews: e.purchasedViews,
        consumedViews: e.consumedViews,
        status: e.status,
        // never include imageUrl as the hash of the signed url changes due to the expiration time      
        imageUrl: "",
      }
      return c
    }))

    await this.setState({
      campaignHash: h,
      campaigns: apiResponse.campaigns
    });
  }

  public async deleteStateCallback(id: string) {
    await this.setState((state: any) => {
      const campaigns = state.campaigns.filter((e: ICampaign) => e.id !== id).map((e: ICampaign) => e);
      return { campaigns, }
    });
  }

  public async selectSlotAt(i: string) {
    await this.setState({ selectedCampaignId: i });
  }

  public async updateCampaignWithId(id: string, props: Map<string, any>) {
    await this.setState((state: any) => {
      const campaigns = state.campaigns.map((e: ICampaign) => {
        if (e.id === id) {
          return Object.assign({}, e, props);
        }
        return e
      });

      return { campaigns, }
    });
  }

  public async addCampaign(camp: ICampaign) {
    await this.setState((state: any) => {
      const campaigns = [...state.campaigns, camp];
      return { campaigns, }
    });
  }

  public render = () => {
    return (
      <Suspense fallback="loading">
        <div className="App">
          <Container className="p-3">
            <Row className='nav-row'>
              <Col className='logo'>
                <Link to='/'>
                  <img src={logo} alt="logo" />
                </Link>
              </Col>
              <Col className='connect-wallet'>
                {this.props.user ?
                  <Container className='login-container'>
                    <div className='muted'>{this.props.user.email}</div>
                    <div><Button className="sc_button" onClick={() => this.props.signOut()}>Sign out</Button></div>
                  </Container>
                  : <Button className="sc_button" onClick={() => this.props.signInWithGoogle()}>Sign in with Google</Button>
                }
              </Col>
            </Row>
          </Container>
          <Container>
            <Outlet context={{
              state: this.state,
              user: this.props.user,
              onLoad: this.onLoad,
              deleteState: this.deleteStateCallback,
              selectSlotAt: this.selectSlotAt,
              updateCampaignWithId: this.updateCampaignWithId,
              addCampaign: this.addCampaign
            }} />

            <div className='learn-more'>
              <Trans i18nKey="learn_more">
                <a href="https://metaverseresidents.com/metaverse-advertising/#skip-to-faq" target={'_blank'} rel="noreferrer">learnmore</a>
              </Trans>
            </div>
          </Container>
        </div>
      </Suspense >);
  }
}

const TranslationApp = withTranslation()(App)
export default TranslationApp;
