import { useEffect, useState } from 'react';
import { Spinner, Toast } from 'react-bootstrap';

import Container from 'react-bootstrap/Container';
import { Trans } from 'react-i18next';
import { useOutletContext, useParams } from 'react-router-dom';
import { Backend } from '../App';
import CampaignEditor from './edit_campaign';
import ScoreCards from './scorecard';

const ControlCenter = () => {
    const ctx = useOutletContext<any>();
    let state = ctx.state;
    let user = ctx.user;
    // this is the only place where we take this state from the url params, 
    // as this allows us to implement back button logic
    let { cid } = useParams();
    let loading = state.campaignHash === "";

    const [errorMessage, setErrorMessage] = useState("");
    const [showErrorAlert, setShowErrorAlert] = useState(false);

    // this effect needs to stay here as the ControlCenter is the actual component being loaded
    useEffect(() => {
        if (!user) {
            return;
        }

        setShowErrorAlert(false);

        user.getIdToken().then((token: string) =>
            fetch(`${Backend}/admin`,
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    if (!response.ok) {
                        return response.json().then(e => new Error(e["message"]))
                    }
                    return response.json();
                })
                .then(data => {
                    if (data instanceof Error) {
                        throw data;
                    }
                    // update the state
                    ctx.onLoad(data);
                }).catch(e => {
                    console.log(e);
                    setErrorMessage(`${e.message} - could not retrieve campaigns, please try again later.`);
                    setShowErrorAlert(true);
                }));
        // eslint-disable-next-line
    }, [user, state.campaignHash]);

    return (
        <Container>
            {!user &&
                <Container>
                    <h1 className="header">
                        <Trans i18nKey="page_1_top_header" />
                    </h1>
                    <h3 className="header">
                        <Trans i18nKey="page_1_top_message" />
                    </h3>
                </Container>
            }
            {user &&
                <Container>
                    {loading &&
                        <Container>
                            {showErrorAlert &&
                                <Toast
                                    bg="danger"
                                    onClose={() => setShowErrorAlert(false)}
                                    show={showErrorAlert}>
                                    <Toast.Body className="text-white">{errorMessage}</Toast.Body>
                                </Toast>
                            }
                            {!showErrorAlert &&
                                <Container>
                                    <p>Loading your campaigns...</p>
                                    <Spinner animation="border" role="status" />
                                </Container>
                            }
                        </Container>
                    }
                    {!loading &&
                        <Container>
                            {cid &&
                                <CampaignEditor />
                            }
                            {!cid &&
                                <ScoreCards />
                            }
                        </Container>
                    }

                </Container>
            }
        </Container >
    );
}

export default ControlCenter;

