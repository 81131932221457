import { Button, Card, Form } from 'react-bootstrap';

import { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Row from 'react-bootstrap/Row';
import { FaSync } from 'react-icons/fa';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { ICampaign } from '../App';


const ScoreCards = () => {
    const ctx = useOutletContext<any>();
    let state = ctx.state;
    const navigate = useNavigate();

    const [campaignFilter, setCampaignFilter] = useState("active");
    const [campaignSearch, setCampaignSearch] = useState("");
    const handleCampaignFilter = (eventKey: any) => setCampaignFilter(eventKey);
    const handleSearch = (event: any) => setCampaignSearch(event.target.value);

    const filterFunc = (e: ICampaign) => {
        let include = false;
        if (campaignFilter) {
            let filterInclude = true;
            if (campaignFilter === "active") {
                filterInclude = e.numViews > 0 && (e.enabled ?? true) && e.status !== "approval_pending";
            } else if (campaignFilter === "finished") {
                filterInclude = e.numViews <= 0;
            } else if (campaignFilter === "disabled") {
                filterInclude = e.enabled != null && !e.enabled;
            } else if (campaignFilter === "partner") {
                filterInclude = e.tenant != null && e.tenant !== "";
            } else if (campaignFilter === "approval") {
                filterInclude = e.status != null && e.status === "approval_pending";
            }
            include = filterInclude;
        }

        if (campaignSearch) {
            let searchInclude = e.id.toLowerCase().includes(campaignSearch);
            if (e.name) {
                searchInclude = searchInclude || e.name.toLowerCase().includes(campaignSearch);
            }
            if (e.email) {
                searchInclude = searchInclude || e.email.toLowerCase().includes(campaignSearch);
            }
            if (e.tenant) {
                searchInclude = searchInclude || e.tenant.toLowerCase().includes(campaignSearch);
            }
            include = include && searchInclude;
        }

        return include;
    }

    return (<Container>
        <Navbar collapseOnSelect expand="lg" bg="transparent" variant="dark">
            <Container>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                        <Nav variant="pills" onSelect={handleCampaignFilter} activeKey={campaignFilter}>
                            <Nav.Item>
                                <Nav.Link eventKey="all">All</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="active">Active</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="finished">Finished</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="partner">Partner</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="approval">Approval</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="disabled">Disabled</Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Nav className='nav-sep-box'>
                            <Nav.Item>
                                <Button className="sc_button refresh-button" onClick={() => ctx.onLoad({ campaigns: [] })}><FaSync /></Button>
                            </Nav.Item>
                            <Nav.Item>
                                <Button className="sc_button create-button" onClick={() => navigate("/new")}>Create Campaign</Button>
                            </Nav.Item>
                            <Nav.Item>
                                <Button className="sc_button create-button" href="https://play.decentraland.org/?position=69%2C-7" target='_blank'>Jump into Metaverse</Button>
                            </Nav.Item>
                            <Nav.Item>
                                <Button className="sc_button create-button" onClick={() => navigate("/agencies")}>Manage Agencies</Button>
                            </Nav.Item>
                        </Nav>
                        <Nav>
                            <Nav.Item>
                                <Form.Control type="text"
                                    className='search-box'
                                    placeholder="Search"
                                    value={campaignSearch}
                                    onChange={handleSearch}
                                />
                            </Nav.Item>
                        </Nav>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
        <Row xs={1} md={4} className="g-4">
            {
                state.campaigns.filter(filterFunc).map(function (object: any, i: number) {
                    return <Col key={"cccol/" + i}>
                        <Card key={"card/" + i} bg="dark" text="white" style={{ margin: '1em' }}>
                            <Card.Img variant="top" src={object.imageUrl} />
                            <Card.Body>
                                <Card.Title>{object.name ?? object.id.substring(0, 10)}</Card.Title>
                                {object.tenant &&
                                    <Container>
                                        <Card.Subtitle>Partner: {object.tenant}</Card.Subtitle>
                                        {(object.numViews <= 0) &&
                                            <Card.Subtitle>Shown {object.consumedViews} times</Card.Subtitle>
                                        }
                                    </Container>
                                }
                                {object.status === "approval_pending" &&
                                    <Card.Subtitle>Awaiting Approval</Card.Subtitle>
                                }

                                <Card.Subtitle>{object.numViews < 0 ? 0 : object.numViews}/{object.purchasedViews} Views</Card.Subtitle>
                            </Card.Body>
                            <Card.Footer>
                                <Button className="sc_button" onClick={() => navigate("/" + object.id)}>Manage</Button>
                            </Card.Footer>
                        </Card>
                    </Col>
                })
            }
        </Row>
    </Container>)
}

export default ScoreCards;
