import { Button, Form, Spinner } from 'react-bootstrap';
import Toast from 'react-bootstrap/Toast';

import { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import Nav from 'react-bootstrap/Nav';
import { FaArrowAltCircleLeft, FaChalkboard, FaDatabase, FaRegCheckCircle, FaSync, FaTrash } from 'react-icons/fa';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { Backend, ICampaign } from '../App';

const CampaignEditor = () => {
    const ctx = useOutletContext<any>();
    let state = ctx.state;
    let user = ctx.user;
    // this is the only place where we take this state from the url params, 
    // as this allows us to implement back button logic
    let { cid } = useParams();
    const navigate = useNavigate();

    const [showSuccessToast, setShowSuccessToast] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [showApprovalConfirmation, setShowApprovalConfirmation] = useState(false);
    const [showCampaignData, setShowCampaignData] = useState(false);

    const handleCloseDeleteConfirmation = () => setShowDeleteConfirmation(false);
    const handleShowDeleteConfirmation = () => setShowDeleteConfirmation(true);
    const handleShowCampaignData = () => setShowCampaignData(!showCampaignData);

    const deleteInBackend = async () => {
        let token = await user.getIdToken()
        let fetchResult = await fetch(`${Backend}/admin?id=${campaign.id}`,
            {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }
            });

        if (fetchResult.ok) {
            handleCloseDeleteConfirmation()
            ctx.deleteState(campaign.id);
            navigate("/")
        } else {
            let body = await fetchResult.json();
            setErrorMessage("Could not delete campaign, error was: " + body.message);
            setShowErrorAlert(true);
        }
    }

    const approveInBackend = async () => {
        setShowErrorAlert(false);
        let token = await user.getIdToken()
        let fetchResult = await fetch(`${Backend}/admin?id=${campaign.id}`,
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({})
            });

        if (fetchResult.ok) {
            setShowSuccessToast(true);
        } else {
            let body = await fetchResult.json();
            setErrorMessage("Could not update campaign, error was: " + body.message);
            setShowErrorAlert(true);
        }
    }

    const updateBackend = async (deltaBody: any) => {
        setShowErrorAlert(false);

        // TODO don't update if theres no change on the property

        let token = await user.getIdToken()
        let fetchResult = await fetch(`${Backend}/admin?id=${campaign.id}`,
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(deltaBody)
            });

        if (fetchResult.ok) {
            setShowSuccessToast(true);
        } else {
            let body = await fetchResult.json();
            setErrorMessage("Could not update campaign, error was: " + body.message);
            setShowErrorAlert(true);
        }
    }

    // check this after we tried to find the correct campaign
    let campaign = state.campaigns.find((e: ICampaign) => e.id === cid);
    if (!campaign) {
        navigate("/")
        return (<Container>
            <p>Trying to find your campaign...</p>
            <Spinner animation="border" role="status" />
        </Container>);
    }

    let awaitingApproval = campaign.status === "approval_pending";

    return (
        <Container>
            <Nav className="me-auto">
                <Nav.Item>
                    <Nav.Link onClick={() => navigate("/")}><FaArrowAltCircleLeft /></Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link disabled style={{ color: "white" }}>
                        {campaign.name ?? campaign.id.substring(0, 10)} ({campaign.dimension})
                    </Nav.Link>
                </Nav.Item>
            </Nav>

            <div>
                {awaitingApproval &&
                    <ButtonGroup>
                        <Button
                            type="submit"
                            className="sc_button"
                            style={{ backgroundColor: 'lightgreen' }}
                            onClick={() => setShowApprovalConfirmation(true)}>
                            Approve
                        </Button>
                        <Button
                            type="submit"
                            className="sc_button"
                            style={{ backgroundColor: 'salmon' }}
                            onClick={handleShowDeleteConfirmation}>
                            <FaTrash style={{ marginRight: '1em' }} />
                            Delete
                        </Button>
                    </ButtonGroup>
                }
                {!awaitingApproval &&
                    <ButtonGroup>
                        <Button
                            type="submit"
                            className="sc_button"
                            onClick={handleShowCampaignData}>
                            <FaDatabase style={{ marginRight: '1em' }} />
                            {showCampaignData ? "Show Image" : "Update Data"}
                        </Button>
                        <Button
                            type="submit"
                            className="sc_button"
                            onClick={() => {
                                ctx.selectSlotAt(cid);
                                navigate('/uploadng');
                            }}>
                            <FaSync style={{ marginRight: '1em' }} />
                            Update Image
                        </Button>
                        <Button
                            type="submit"
                            className="sc_button"
                            onClick={() => window.open(`https://datastudio.google.com/reporting/a7601065-4a4e-4675-a47c-8c54cc28ea5d/page/p_jmfsf10cuc?params=%7B%22campaign%22:%22${encodeURIComponent(campaign.id)}%22%7D`, '_blank', 'noopener,noreferrer')}>
                            <FaChalkboard style={{ marginRight: '1em' }} />
                            View Report
                        </Button>
                        <Button
                            type="submit"
                            className="sc_button"
                            style={{ backgroundColor: 'salmon' }}
                            onClick={handleShowDeleteConfirmation}>
                            <FaTrash style={{ marginRight: '1em' }} />
                            Delete
                        </Button>
                    </ButtonGroup>
                }

                <Modal show={showDeleteConfirmation} onHide={handleCloseDeleteConfirmation}>
                    <Modal.Header closeButton>
                        <Modal.Title>Deleting the campaign</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Do you really want to delete this campaign? This action is irreversible.</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseDeleteConfirmation}>
                            Cancel
                        </Button>
                        <Button variant="danger" onClick={deleteInBackend}>
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showApprovalConfirmation} onHide={() => setShowApprovalConfirmation(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Approve the campaign</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Do you really want to approve this campaign?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowApprovalConfirmation(false)}>
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={approveInBackend}>
                            Approve
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
            <Toast
                bg="success"
                onClose={() => setShowSuccessToast(false)}
                show={showSuccessToast}
                delay={5000}
                autohide>
                <Toast.Body className="text-white"><FaRegCheckCircle />Update Successful</Toast.Body>
            </Toast>
            <Toast
                bg="danger"
                onClose={() => setShowErrorAlert(false)}
                show={showErrorAlert}
                delay={5000}
                autohide>
                <Toast.Body className="text-white">{errorMessage}</Toast.Body>
            </Toast>
            {(campaign.numViews > 0) && campaign.enabled === false &&
                <Alert variant="warning">
                    Your campaign is disabled, please enable or update image to enable automatically.
                </Alert>
            }
            {(campaign.numViews < 0) &&
                <Alert variant="warning">
                    Views expired: your campaign is no longer shown in the Metaverse.
                </Alert>
            }
            <br />
            {!showCampaignData &&
                <img src={campaign.imageUrl} className="extend-img" alt="campaign" />
            }
            {showCampaignData &&
                <Form className='tnc-form container justify-content-center align-items-center'>
                    <Form.Group className="mb-3">
                        <Form.Label>Enabled</Form.Label>
                        <Form.Check type="switch"
                            checked={campaign.enabled ?? true}
                            onChange={_ => {
                                let toggle = !campaign.enabled;
                                ctx.updateCampaignWithId(campaign.id, { "enabled": toggle })
                                updateBackend({ "enabled": toggle })
                            }}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Dimension</Form.Label>
                        <Form.Control defaultValue={campaign.dimension} readOnly />
                    </Form.Group>

                    <Form.Group className="mb-3" >
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text"
                            maxLength={20}
                            value={campaign.name}
                            onChange={event => {
                                ctx.updateCampaignWithId(campaign.id, { "name": event.target.value })
                            }}
                            onBlur={_ => {
                                updateBackend({ "name": campaign.name })
                            }}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email"
                            value={campaign.email}
                            onChange={event => {
                                ctx.updateCampaignWithId(campaign.id, { "email": event.target.value })
                            }}
                            onBlur={_ => {
                                updateBackend({ "email": campaign.email })
                            }}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Views Remaining</Form.Label>
                        <Form.Control type="number"
                            value={campaign.numViews}
                            onChange={event => {
                                ctx.updateCampaignWithId(campaign.id, { "numViews": Number(event.target.value) })
                            }}
                            onBlur={_ => {
                                updateBackend({ "numViews": Number(campaign.numViews) })
                            }}

                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Purchased Views</Form.Label>
                        <Form.Control type="number"
                            value={campaign.purchasedViews}
                            onChange={event => {
                                ctx.updateCampaignWithId(campaign.id, { "purchasedViews": Number(event.target.value) })
                            }}
                            onBlur={_ => {
                                updateBackend({ "purchasedViews": Number(campaign.purchasedViews) })
                            }}
                        />
                    </Form.Group>
                </Form>
            }
        </Container>
    )
}

export default CampaignEditor;
